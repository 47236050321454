const UseForm = async (data) => {
  const url = "https://home.jo3.cc/api/query";
  console.log(data);

  const submitRequest = async (reqBody) => {
    try {
      console.log(reqBody);
      const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input: reqBody }),
      });
      const json = await res.json();
      return { response: json, error: undefined };
    } catch (error) {
      return { response: undefined, error: error };
    }
  };

  return await submitRequest(data);
};

export default UseForm;
