import React from 'react';
import { useState } from "react";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  TreeSelect,
  Space, Table, Tag, Spin,
} from 'antd';
import type { TableProps } from 'antd';
import UseForm from "./UseForm.js";
const { Option } = Select;
const { RangePicker } = DatePicker;


const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

// reference: https://github.com/canonic-dev/canonic-samples/blob/main/canonic-forms/src/components/Contact%20Form/ContactForm.js
const App = () => {
   const initData: DataType[] = [
    {
      key: '1',
      address: '420 Station Park Circle',
      price: 1000000,
      url: 'https://example.com/',
    },
   ];

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);

  const handleSubmission = React.useCallback(
    (result) => {
      if (result.error) {
	console.log(result)
	console.log("ERROR!");
      } else {
	console.log(result.response)
	console.log("SUCCESS!");
	setData(result.response)
	//form.resetFields();
      }
    },
    [form]
  );

  const onSubmit = React.useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (errorInfo) {
      console.log(errorInfo);
      return;
    }
    setLoading(true);
    const result = await UseForm(values);
    setLoading(false);
    handleSubmission(result);
  }, [form, handleSubmission]);


  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <a href={text} target="_blank">Zillow Link</a>,
    },
  ];
  
  return (

  <div>
  <Form
    {...formItemLayout}
    variant="filled"
    form={form}
    style={{
      maxWidth: 600,
    }}
  >
    <Form.Item
      label="MinPrice"
      name="MinPrice"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <InputNumber
        style={{
          width: '100%',
        }}
      />
    </Form.Item>

    <Form.Item
      label="MaxPrice"
      name="MaxPrice"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <InputNumber
        style={{
          width: '100%',
        }}
      />
    </Form.Item>



    <Form.Item
        name="ZipCode"
        label="ZipCode"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
        >
          <Option value="20814">20814</Option>
          <Option value="20854">20854</Option>
          <Option value="20817">20817</Option>
          <Option value="22101">22101</Option>
          <Option value="22102">22102</Option>
          <Option value="94402">94402</Option>
          <Option value="94303">94303</Option>
        </Select>
      </Form.Item>


    <Form.Item
        name="HomeType"
        label="HomeType"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
        >
          <Option value="SINGLE_FAMILY">SINGLE_FAMILY</Option>
          <Option value="CONDO">CONDO</Option>
          <Option value="TOWNHOUSE">TOWNHOUSE</Option>
	  <Option value="MULTI_FAMILY">MULTI_FAMILY</Option>
	  <Option value="LOT">LOT</Option>
        </Select>
      </Form.Item>



    <Form.Item
      label="LLM Query"
      name="LLM Query"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input.TextArea placeholder="Example: can you tell me if this house has a pool?" />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 6,
        span: 16,
      }}
    >
      <Button type="primary" onClick={onSubmit}>
        Submit
      </Button>
    </Form.Item>
  </Form>

  {loading === true && <Spin tip="Loading... This may take a few minutes" size="large">Loading ...</Spin>}
  {loading === false && <Table columns={columns} dataSource={data} />} 
  </div>
);

};
export default App;

const onFinish = (values) => {
    console.log(values);
};

